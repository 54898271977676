import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import LOGO from "../../assets/logo.png";
import Logout from "./Logout";
import { motion } from "framer-motion";

import { CiDiscount1 } from "react-icons/ci";

import { LiaPaperPlaneSolid } from "react-icons/lia";
import { IoBagHandleOutline } from "react-icons/io5";
import {
  MdInsertChartOutlined,
  MdOutlineGroups,
  MdOutlineSchool,
  MdOutlineShoppingCart,
} from "react-icons/md";
import { TbUsersPlus } from "react-icons/tb";
import { IoMdFootball } from "react-icons/io";
const SideNav = () => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const location = useLocation();

  const toggleSidebar = () => {
    setIsCollapsed(!isCollapsed);
  };

  const menus = [
    { name: "Dashboard", link: "/dashboard", icon: <MdInsertChartOutlined /> },
    {
      name: "Groups",
      link: "/dashboard/groups",
      icon: <MdOutlineGroups />,
    },
    {
      name: "Learning Center",
      link: "/dashboard/courses",
      icon: <MdOutlineSchool />,
    },
    {
      name: "Store",
      link: "/dashboard/buy_new_license",
      icon: <IoBagHandleOutline />,
    },
    {
      name: "Users",
      link: "/dashboard/all_users",
      icon: <TbUsersPlus />,
    },
    {
      name: "Campaigne Hub",
      link: "/dashboard/campaigns",
      icon: <LiaPaperPlaneSolid />,
    },
    {
      name: "Cart",
      link: "/dashboard/mycart",
      icon: <MdOutlineShoppingCart />,
    },
    { name: "Discount", link: "/dashboard/discounts", icon: <CiDiscount1 /> },
    {
      name: "Knowledgebase",
      link: "/dashboard/knwoledge",
      icon: <IoMdFootball />,
    },
  ];

  return (
    <motion.section
      initial={{ x: -260 }}
      animate={{ x: 0 }}
      transition={{ duration: 0.5 }}
      className="flex"
    >
      <div
        className={`bg-white min-h-screen shadow-lg duration-300 text-black px-4 py-6 flex flex-col
          ${isCollapsed ? "w-20" : "w-64"}`}
      >
        <motion.div
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.2 }}
          className="py-3 flex bg-white justify-center items-center cursor-pointer"
          onClick={toggleSidebar}
        >
          <img
            className={`transition-all duration-300 ${
              isCollapsed ? "w-10" : "w-32"
            }`}
            src={LOGO}
            alt="Logo"
          />
        </motion.div>

        <nav className="mt-8 flex flex-col gap-2 flex-grow">
          {menus.map((menu, i) => (
            <motion.div
              key={i}
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ delay: i * 0.1 }}
            >
              <Link
                to={menu.link}
                className={`group flex items-center text-base gap-3 font-poppins py-3 
                  ${isCollapsed ? "px-2 justify-center" : "px-4"} 
                  rounded-md transition-all duration-200
                  ${
                    location.pathname === menu.link
                      ? "bg-[#00486F] text-white"
                      : "text-black hover:bg-[rgba(112,127,221,0.1)] hover:text-[#00486F]"
                  }`}
                title={isCollapsed ? menu.name : ""}
              >
                <span className={`text-xl ${isCollapsed ? "mx-auto" : ""}`}>
                  {menu.icon}
                </span>
                {!isCollapsed && (
                  <span className="font-medium">{menu.name}</span>
                )}
              </Link>
            </motion.div>
          ))}
        </nav>

        <Logout isOpen={!isCollapsed} />
      </div>
    </motion.section>
  );
};

export default SideNav;
