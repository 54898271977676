import React, { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { useAuth } from "../../AuthContext";
import axiosInstance from "../../utils/axiosConfig";

const SubscriberLoginPage = () => {
	const { login } = useAuth();

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const [passwordVisible, setPasswordVisible] = useState(false);

	const togglePasswordVisibility = () => {
		setPasswordVisible(!passwordVisible);
	};

	const [buttonPressed, setbuttonPressed] = useState(false);
	const [emailError, setEmailError] = useState("");
	const [passError, setPassError] = useState("");
	const [email, setEmail] = useState("");
	const [pass, setPass] = useState("");
	const [errorMsg, setErrorMsg] = useState("");

	const navigate = useNavigate();

	const handleEmailChange = (event) => {
		const email = event.target.value;
		setEmail(email);
		if (email.trim() === "") {
			setEmailError("Email is required");
		} else if (!isValidEmail(email)) {
			setEmailError("Invalid email format");
		} else {
			setEmailError("");
		}
	};
	const handlePassChange = (event) => {
		const pass = event.target.value;
		setPass(pass);
		if (pass.trim() === "") {
			setPassError("Password is Required");
		} else if (pass.length <= 2) {
			setPassError("Password cannot be of length: " + pass.length);
		} else {
			setPassError("");
		}
	};

	const isValidEmail = (email) => {
		const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
		return emailPattern.test(email);
	};

	async function handleSignIn() {
		try {
			setbuttonPressed(true);
			const response = await axiosInstance.post("/v1/auth/subscriber_login", {
				email: email,
				password: pass,
			});
			const { user } = response.data;
			setbuttonPressed(false);
			login(user);
			navigate("/subscriber_dashboard");
		} catch (error) {
			console.error("Login error:", error);
			setErrorMsg("Error signing in");
			setbuttonPressed(false);
		}
	}

	return (
		<>
			<div className="font-sans text-gray-900 antialiased">
				<div className="min-h-screen flex flex-col lg:flex-row justify-center sm:justify-center items-center pt-6 sm:pt-0 bg-[#f8f4f3]">
					<div className="flex md:mb-0 mb-[25px] rounded-md w-[95%] md:w-[50%] flex-col justify-center items-center gap-2">
						<div>
							<Link to="/">
								<h2 className="font-bold text-3xl">
									Subscriber{" "}
									<span className="bg-[#00486F] text-white px-2 rounded-md">
										PORTAL
									</span>
								</h2>
							</Link>
						</div>

						<div className="w-[95%] sm:max-w-md mt-6 px-6 py-4 bg-white shadow-md overflow-hidden sm:rounded-lg">
							<div className="py-8">
								<center>
									<span className="text-2xl font-semibold">Authentication</span>
								</center>
							</div>

							<div>
								<label
									htmlFor="email"
									className="block font-medium text-sm text-gray-700"
								>
									Email
								</label>
								<input
									placeholder="Email"
									className="w-full rounded-md py-2.5 px-4 border text-sm outline-[#f84525]"
									name="emailinput"
									type="email"
									required
									onChange={handleEmailChange}
								/>
							</div>

							<div className="mt-4">
								<label
									htmlFor="password"
									className="block font-medium text-sm text-gray-700"
								>
									Password
								</label>
								<div className="relative">
									<input
										id="password"
										type={passwordVisible ? "text" : "password"}
										name="password"
										minLength={2}
										placeholder="Password"
										onChange={handlePassChange}
										required
										autoComplete="current-password"
										className="w-full relative rounded-md py-2.5 px-4 border text-sm outline-[#f84525]"
									/>
									<button
										type="button"
										id="togglePassword"
										onClick={togglePasswordVisibility}
										className="text-gray-500 focus:outline-none focus:text-gray-600 hover:text-gray-600 absolute inset-y-0 right-0 pr-3 flex items-center"
									>
										<svg
											xmlns="http://www.w3.org/2000/svg"
											className="absolute top-2 right-2 w-6 h-6"
											viewBox=" 0 0 24 24"
										>
											<path d="M12 4.998c-1.836 0-3.356.389-4.617.971L3.707 2.293 2.293 3.707l3.315 3.316c-2.613 1.952-3.543 4.618-3.557 4.66l-.105.316.105.316C2.073 12.382 4.367 19 12 19c1.835 0 3.354-.389 4.615-.971l3.678 3.678 1.414-1.414-3.317-3.317c2.614-1.952 3.545-4.618 3.559-4.66l.105-.316-.105-.316c-.022-.068-2.316-6.686-9.949-6.686zM4.074 12c.103-.236.274-.586.521-.989l5.867 5.867C6.249 16.23 4.523 13.035 4.074 12zm9.247 4.907-7.48-7.481a8.138 8.138 0 0 1 1.188-.982l8.055 8.054a8.835 8.835 0 0 1-1.763.409zm3.648-1.352-1.541-1.541c.354-.596.572-1.28.572-2.015 0-.474-.099-.924-.255-1.349A.983.983 0 0 1 15 11a1 1 0 0 1-1-1c0-.439.288-.802.682-.936A3.97 3.97 0 0 0 12 7.999c-.735 0-1.419.218-2.015.572l-1.07-1.07A9.292 9.292 0 0 1 12 6.998c5.351 0 7.425 3.847 7.926 5a8.573 8.573 0 0 1-2.957 3.557z"></path>
										</svg>
									</button>
								</div>
							</div>

							<div className="flex flex-col gap-3 items-center justify-center mt-4">
								<button
									onClick={handleSignIn}
									className="text-center flex flex-col justify-center items-center w-full px-4 py-2  bg-[#00486F] border border-transparent rounded-md font-semibold text-xs text-white uppercase tracking-widest hover:bg-[#00314D] focus:bg-gray-700 active:bg-gray-900 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 transition ease-in-out duration-150"
								>
									{buttonPressed ? (
										<div className="flex flex-row justify-center gap-1 items-center">
											<svg
												className="text-white animate-spin h-5 w-5 mr-3"
												viewBox="0 0 24 24"
											>
												<svg
													xmlns="http://www.w3.org/2000/svg"
													fill="none"
													viewBox="0 0 24 24"
													strokeWidth="1.5"
													stroke="currentColor"
													className="w-6 h-6"
												>
													<path
														strokeLinecap="round"
														strokeLinejoin="round"
														d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99"
													/>
												</svg>
											</svg>
											Processing...
										</div>
									) : (
										"LOGIN"
									)}
								</button>
								<label
									htmlFor=""
									className="text-xs text-red-600 text-center font-semibold px-1"
								>
									{errorMsg.length >= 0 ? errorMsg : ""}
								</label>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default SubscriberLoginPage;
