import React, { useEffect, useState } from "react";
import UserReportTable from "../components/UserReportTable";
import CampaignReportTable from "../components/CampaignReportTable";
import ModuleReportTable from "../components/ModuleReportTable";
import { FaChevronDown } from "react-icons/fa6";
import axiosInstance from "../../utils/axiosConfig";
import { useAuth } from "../../AuthContext";
import { ReportsSection } from "../../components/ReportSection";
import { GoPhishAccountAPIKey, GoPhishPublicURL } from "../../utils/constants";

const DashboardPage = (viewemail) => {
  const { currentUser } = useAuth();
  let email;

  if (viewemail && viewemail.viewemail) {
    email = viewemail.viewemail; // Assign viewemail if it exists
  } else {
    email = currentUser.email; // Fallback to currentUser.email
  }
  const [activeButton, setActiveButton] = useState("campaign");
  const [trainingOverviewOption, setTrainingOverviewOption] = useState(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [numOfUsers, setNumOfUsers] = useState("");
  const [numOfGroups, setNumOfGroups] = useState("");
  const [numOfCampaigns, setNumOfCampaigns] = useState("");

  const handleButtonClick = (buttonName) => {
    setActiveButton(buttonName);
  };

  const handleDropdownSelection = (option) => {
    setTrainingOverviewOption(option);
    setIsDropdownOpen(false);
    setSearchTerm("");
  };

  const handleClearSelection = () => {
    setTrainingOverviewOption(null);
  };

  const filteredOptions = ["User Report", "Module Report"].filter((option) =>
    option.toLowerCase().includes(searchTerm.toLowerCase())
  );
  const getReports = async () => {
    try {
      const resp = await axiosInstance.post(`/modules_report/stats`, {
        email,
      });

      if (resp?.data?.status === 200) {
        const {
          total_users_in_groups,
          total_groups,
          campaigns,
          groups_details,
        } = resp?.data?.data;

        if (total_users_in_groups !== undefined && total_groups !== undefined) {
          setNumOfUsers(total_users_in_groups);
          setNumOfGroups(total_groups);
        } else {
          setNumOfUsers(0);
          setNumOfGroups(0);
        }
      } else {
        setNumOfUsers(0);
        setNumOfGroups(0);
      }
    } catch (error) {
      console.error("Error fetching reports data:", error.message);

      setNumOfUsers(0);
      setNumOfGroups(0);
    }
  };

  useEffect(() => {
    if (email) {
      getReports();
      getCampaignDataSummary();
    }
  }, []);
  const getCampaignDataSummary = async () => {
    try {
      const response = await fetch(
        `${GoPhishPublicURL}/api/campaigns/summary/?api_key=${GoPhishAccountAPIKey}`
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      // const userEmail = currentUser.email;

      const userCampaigns = data.campaigns.filter((campaign) =>
        campaign.name.includes(`email: ${email}`)
      );

      let filteredCampaigns = userCampaigns;

      filteredCampaigns.forEach((campaign) => {
        // Remove email and groups from the campaign name
        const emailPattern = new RegExp(`, email: ${email}`);
        const groupsPattern = /, groups: .*/;

        campaign.name = campaign.name
          .replace(emailPattern, "")
          .replace(groupsPattern, "")
          .trim();
      });
      const inProgressCount = filteredCampaigns.filter(
        (campaign) => campaign.status === "In progress"
      ).length;

      setNumOfCampaigns(inProgressCount);
    } catch (error) {
      console.error("Error fetching campaign data summary:", error);
    }
  };

  useEffect(() => {
    setActiveButton("campaign");
  }, []);

  return (
    <>
      <ReportsSection
        numOfUsers={numOfUsers}
        numOfGroups={numOfGroups}
        numOfcampaigns={numOfCampaigns}
        groupleader={false}
      />
      <div className="w-full h-auto flex flex-row justify-start items-start gap-3 mb-6">
        <button
          className={`px-4 py-2 font-semibold text-gray-500 rounded-lg ${
            activeButton === "campaign"
              ? "bg-gray-300 border-0"
              : "border-[1px] border-gray-300"
          }`}
          onClick={() => handleButtonClick("campaign")}
        >
          Campaign Report
        </button>
        <button
          className={`px-4 py-2 font-semibold text-gray-500 rounded-lg ${
            activeButton === "training"
              ? "bg-gray-300 border-0"
              : "border-[1px] border-gray-300"
          }`}
          onClick={() => handleButtonClick("training")}
        >
          Training Overview
        </button>
      </div>

      {activeButton === "campaign" && (
        <CampaignReportTable viewemail={viewemail} />
      )}

      {activeButton === "training" && (
        <div>
          <div className="w-full flex flex-col justify-center items-center">
            <div className="w-full h-16 flex flex-row justify-start items-center rounded-t-lg text-gray-700 font-semibold text-base gap-4 p-2 bg-[#e1ecf0]">
              <div className="relative">
                <div className="flex justify-center items-center">
                  <button
                    className="bg-white border border-gray-300 rounded-full px-3 py-2 text-left font-medium  focus:outline-none focus:ring-2 focus:ring-primary"
                    onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                  >
                    {trainingOverviewOption || "Select View"}
                    <FaChevronDown className="inline-block ml-3 h-3 w-3 " />
                  </button>
                  {trainingOverviewOption && (
                    <button
                      className="ml-2  hover:text-gray-700 focus:outline-none"
                      onClick={handleClearSelection}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-5 w-5"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fillRule="evenodd"
                          d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </button>
                  )}
                </div>
                {isDropdownOpen && (
                  <div className="absolute z-10 w-64 mt-1 bg-white border border-gray-300 rounded-md shadow-lg">
                    <ul className="max-h-48 overflow-auto">
                      {filteredOptions.map((option) => (
                        <li
                          key={option}
                          className="px-3 py-2 hover:bg-gray-100 cursor-pointer"
                          onClick={() => handleDropdownSelection(option)}
                        >
                          {option}
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>
            </div>
          </div>

          {trainingOverviewOption === "User Report" && (
            <UserReportTable viewemail={viewemail} />
          )}
          {trainingOverviewOption === "Module Report" && (
            <ModuleReportTable viewemail={viewemail} />
          )}
        </div>
      )}
    </>
  );
};

export default DashboardPage;
