import React, { useEffect, useState } from "react";
import {
  Button,
  Typography,
  Box,
  Card,
  CardContent,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Fade,
  CircularProgress,
  IconButton,
  Tooltip,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axiosInstance from "../../utils/axiosConfig";

const StyledCardContent = styled(CardContent)({
  flexGrow: 1,
  padding: "24px",
});

const PriceTypography = styled(Typography)({
  marginTop: "16px",
  "& .price": {
    fontSize: "2.5rem",
    fontWeight: 300,
    color: "rgba(0, 0, 0, 0.87)",
  },
  "& .period": {
    fontSize: "1rem",
    color: "rgba(0, 0, 0, 0.6)",
  },
});

const StyledCard = styled(Card, {
  shouldForwardProp: (prop) => prop !== "selected",
})(({ selected }) => ({
  display: "flex",
  flexDirection: "column",
  height: "100%",
  borderRadius: "24px",
  cursor: "pointer",
  transition: "all 0.3s ease",
  backgroundColor: selected ? "#e3f2fd" : "#ffffff",
  "&:hover": {
    boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
    transform: "translateY(-5px)",
  },
}));

const LoadingOverlay = styled(Box)({
  position: "absolute",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: "rgba(255, 255, 255, 0.7)",
  zIndex: 1,
});
const Loader = () => {
  return (
    <div className="w-full h-full flex items-center justify-center mt-4 opacity-75 z-50">
      <div
        class="loader border-t-2 rounded-full border-gray-500 bg-gray-300 animate-spin
aspect-square w-16 flex justify-center items-center text-yellow-700"
      ></div>
    </div>
  );
};

export default function Component() {
  const [discounts, setDiscounts] = useState([]);
  const [open, setOpen] = useState(false);
  const [editIndex, setEditIndex] = useState(-1);
  const [newDiscount, setNewDiscount] = useState({ seats: "", percentage: "" });
  const [loading, setLoading] = useState(false);

  const handleOpen = (index = -1) => {
    setEditIndex(index);
    if (index >= 0) {
      setNewDiscount(discounts[index]);
    } else {
      setNewDiscount({ seats: "", percentage: "" });
    }
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setEditIndex(-1);
  };

  const handleSave = async () => {
    setLoading(true);
    try {
      const endpoint =
        editIndex >= 0 ? "/seats_discounts/update" : "/seats_discounts/add";
      const payload = {
        seats: newDiscount.seats,
        percentage: newDiscount.percentage,
      };

      const response = await axiosInstance[editIndex >= 0 ? "put" : "post"](
        endpoint,
        payload
      );

      if (response.data.success) {
        toast.success(
          editIndex >= 0
            ? "Discount updated successfully"
            : "Discount added successfully"
        );
        if (editIndex >= 0) {
          setDiscounts(
            discounts.map((discount, i) =>
              i === editIndex ? newDiscount : discount
            )
          );
        } else {
          fetchDiscounts();
        }
      } else {
        toast.error(
          `Failed to ${editIndex >= 0 ? "update" : "add"} discount: ${
            response.data.message
          }`
        );
      }
    } catch (error) {
      toast.error(
        `Error ${editIndex >= 0 ? "updating" : "adding"} discount: ${error}`
      );
    }
    setLoading(false);
    handleClose();
  };

  const removeDiscount = async (index) => {
    setLoading(true);
    const discountToDelete = discounts[index];

    try {
      const response = await axiosInstance.delete("/seats_discounts/delete", {
        data: { seats: discountToDelete.seats },
      });

      if (response.data.success) {
        toast.success("Discount Deleted Successfully");
        setDiscounts(discounts.filter((_, i) => i !== index));
      } else {
        toast.error(`Failed to delete discount: ${response.data.message}`);
      }
    } catch (error) {
      toast.error(`Error deleting discount: ${error}`);
    }
    setLoading(false);
  };

  const fetchDiscounts = async () => {
    setLoading(true);
    try {
      const response = await axiosInstance.get("/seats_discounts/get");
      if (response.data.success) {
        setDiscounts(response.data.data);
      } else {
        console.error("Failed to fetch discounts:", response.data.message);
      }
    } catch (error) {
      console.error("Error fetching discounts:", error);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchDiscounts();
  }, []);

  return (
    <Box sx={{ width: "full", mx: "auto", mt: 1, p: 3 }}>
      {loading && (
        <LoadingOverlay>
          <Loader />
        </LoadingOverlay>
      )}
      <Fade in={!loading}>
        <Box>
          <Typography variant="h4" gutterBottom sx={{ mb: 4 }}>
            Seat-based Discount Configuration
          </Typography>

          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "repeat(auto-fill, minmax(240px, 1fr))",
              gap: 3,
              mb: 4,
            }}
          >
            {discounts.map((discount, index) => (
              <StyledCard key={index} selected={false}>
                <StyledCardContent>
                  <Typography variant="h6" component="div">
                    Discount Tier {index + 1}
                  </Typography>
                  <PriceTypography variant="h5" component="div">
                    <span className="price">{discount.seats}</span>
                    <span className="period"> seats</span>
                  </PriceTypography>
                  <PriceTypography variant="h5" component="div">
                    <span className="price">{discount.percentage}%</span>
                    <span className="period"> discount</span>
                  </PriceTypography>
                  <Box
                    sx={{
                      mt: 2,
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Tooltip title="Edit Discount">
                      <IconButton
                        onClick={() => handleOpen(index)}
                        color="primary"
                        size="small"
                      >
                        <EditIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Delete Discount">
                      <IconButton
                        onClick={() => removeDiscount(index)}
                        color="error"
                        size="small"
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>
                  </Box>
                </StyledCardContent>
              </StyledCard>
            ))}
          </Box>

          <Button
            startIcon={<AddIcon />}
            onClick={() => handleOpen()}
            variant="contained"
            color="primary"
            sx={{
              fontWeight: "bold",
              textTransform: "none",
              px: 3,
              py: 1,
              borderRadius: 2,
              bgcolor: "#299aa1",
              boxShadow: 2,
              "&:hover": {
                bgcolor: "#00A3AE",
                boxShadow: 4,
              },
            }}
          >
            Add Discount Tier
          </Button>

          <Dialog open={open} onClose={handleClose}>
            <DialogTitle
              sx={{
                marginBottom: "20px",
                backgroundColor: "#00486f",
                color: "white",
                fontWeight: "bold",
              }}
            >
              {editIndex >= 0 ? "Edit" : "Add"} Discount Tier
            </DialogTitle>
            <DialogContent>
              <TextField
                label="Seats"
                type="number"
                value={newDiscount.seats}
                onChange={(e) =>
                  setNewDiscount({ ...newDiscount, seats: e.target.value })
                }
                fullWidth
                margin="normal"
              />
              <TextField
                label="Discount (%)"
                type="number"
                value={newDiscount.percentage}
                onChange={(e) =>
                  setNewDiscount({
                    ...newDiscount,
                    percentage: e.target.value,
                  })
                }
                fullWidth
                margin="normal"
              />
            </DialogContent>
            <DialogActions
              sx={{
                padding: "16px", // Add padding to enhance spacing in DialogActions
                display: "flex",

                // justifyContent: "space-between", // Space the buttons apart for a better UI
              }}
            >
              {/* Cancel button with red color */}
              <Button
                onClick={handleClose}
                sx={{
                  width: "84px",
                  color: "white", // White text for better readability
                  backgroundColor: "#d32f2f", // Red background
                  "&:hover": {
                    backgroundColor: "#b71c1c", // Darker red on hover
                  },
                }}
                variant="contained"
              >
                Cancel
              </Button>

              {/* Add button with custom color */}
              <Button
                onClick={handleSave}
                sx={{
                  width: "84px",
                  color: "white", // White text for contrast
                  backgroundColor: "#02496F", // Custom blue color for Add
                  "&:hover": {
                    backgroundColor: "#023A5A", // Slightly darker shade on hover
                  },
                }}
                variant="contained"
              >
                Add
              </Button>
            </DialogActions>
            {/* <DialogActions>
              <Button onClick={handleClose} disabled={loading}>
                Cancel
              </Button>
              <Button
                onClick={handleSave}
                variant="contained"
                color="primary"
                disabled={loading} // Disable button while loading
              >
                {loading
                  ? editIndex >= 0
                    ? "Updating..."
                    : "Adding..."
                  : editIndex >= 0
                  ? "Update"
                  : "Add"}
              </Button>
            </DialogActions> */}
          </Dialog>
        </Box>
      </Fade>
    </Box>
  );
}
