import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import GroupLeader from "./sections/GroupLeader";
// import Subscriber from "./sections/Subscriber";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { Add as AddIcon, People as PeopleIcon } from "@mui/icons-material";
import DialogTitle from "@mui/material/DialogTitle";
import {
  Book as BookIcon,
  Collections as CollectionsIcon,
} from "@mui/icons-material";
import {
  FormLabel,
  FormControlLabel,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Radio,
  RadioGroup,
  Typography,
  Box,
  styled,
  CardContent,
  Card,
  CardActionArea,
  Paper,
  Grid,
  Chip,
  Tab,
  Tabs,
  Modal,
  ListItemIcon,
  ListItemText,
  Menu,
} from "@mui/material";
import axiosInstance from "../../utils/axiosConfig";
import { useAuth } from "../../AuthContext";
import axios from "axios";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { GoPhishAccountAPIKey, GoPhishPublicURL } from "../../utils/constants";
import { Loader } from "../../utils/Loader";
import { Link, useParams } from "react-router-dom";
import GroupLeader from "../../dashboard/pages/sections/GroupLeader";
import Subscriber from "../../dashboard/pages/sections/Subscriber";

const StyledCard = styled(Card, {
  shouldForwardProp: (prop) => prop !== "selected",
})(({ selected }) => ({
  display: "flex",
  flexDirection: "column",
  height: "100%",
  borderRadius: "24px",
  cursor: "pointer",
  transition: "all 0.3s ease",
  backgroundColor: selected ? "#e3f2fd" : "#ffffff",
  boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
}));
const StyledCardContent = styled(CardContent)({
  flexGrow: 1,
  padding: "24px",
});
const TabPanel = ({ children, value, index, ...other }) => {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

const a11yProps = (index) => {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
};
const GroupLeaderGroupManagmentPage = () => {
  const { currentUser } = useAuth();

  const [coursesData, setCoursesData] = useState([]);
  const [bundlesData, setBundlesData] = useState([]);
  const [loading, setLoading] = useState(false);

  const [AllGroups, setAllGroups] = useState([]);
  const [SelectedGroupName, setSelectedGroupName] = useState("");
  const [SelectedCourseTitle, setSelectedCourseTitle] = useState("");
  const [SelectedGroupId, setSelectedGroupId] = useState("");
  const [SelectedCourseId, setSelectedCourseId] = useState("");
  const [SelectedBundleTitle, setSelectedBundleTitle] = useState("");
  const [SelectedBundleId, setSelectedBundleId] = useState("");
  const [addgroupmodal, setaddgroupmodal] = useState(false);
  const [value, setValue] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedOption, setSelectedOption] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [modalData, setModalData] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [groupId, setGroupId] = useState(null);
  const [groupName, setGroupName] = useState("");

  const pathname = window.location.pathname;

  const isGroupLeaderDashboard = pathname.includes("groupleader_dashboard");

  const fetchGroupIdAndName = async () => {
    try {
      // Fetch the group IDs for the current user
      const groupIdResponse = await axiosInstance.get(
        `/groups/${currentUser?.id}/groupid`
      );

      const retrievedGroupIds = groupIdResponse?.data?.group_id;

      setGroupId(retrievedGroupIds);

      if (retrievedGroupIds && retrievedGroupIds.length > 0) {
        const groupNameResponse = await axiosInstance.post(
          `groups/group/details`,
          { group_id: retrievedGroupIds }
        );

        setAllGroups(groupNameResponse?.data?.groups);

        const retrievedGroupNames = groupNameResponse?.data?.groups?.map(
          (group) => group.name
        );

        setGroupName(retrievedGroupNames);
      }
    } catch (error) {
      console.error("Error fetching group data!", error);
    }
  };
  useEffect(() => {
    fetchGroupIdAndName();
  }, []);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const [formData, setFormData] = useState({
    groupname: "",
    signinType: "with_pwd",
    gl_firstname: "",
    gl_lastname: "",
    gl_email: "",
  });

  // const fetchAllGroups = async () => {
  //   try {
  //     const resp = await axiosInstance.get(`/groups/all/${currentUser.email}`);
  //     setAllGroups(resp.data.groups);
  //   } catch (error) {
  //     console.log("Error fetching all groups!");
  //   }
  // };

  // useEffect(() => {
  //   fetchAllGroups();
  // }, [currentUser]);

  const handleAddGroup = () => {
    setaddgroupmodal(true);
  };

  const handleCloseGroupModal = () => {
    setaddgroupmodal(false);
  };

  const handleGroupSubmit = async () => {
    try {
      const group_data = await axios.post(
        `${GoPhishPublicURL}/api/groups/?api_key=${GoPhishAccountAPIKey}`,
        {
          name: formData.groupname,
          targets: [
            {
              email: formData.gl_email,
              first_name: formData.gl_firstname,
              last_name: formData.gl_lastname,
              position: "Group Leader",
            },
          ],
        }
      );
      const resp = await axiosInstance.post("/groups/add", {
        name: formData.groupname,
        signin_type: formData.signinType,
        creator_role: currentUser.role,
        creator_email: currentUser.email,
        gophish_groupid: group_data.data.id,
      });
      await axiosInstance.post(`/groupleaders/add`, {
        firstname: formData.gl_firstname,
        lastname: formData.gl_lastname,
        email: formData.gl_email,
        group_id: resp.data.ids.lms_groupid,
        currentUser: currentUser,
      });
      if (resp.status === 201) {
        toast.success("Group created successfully");
        handleCloseGroupModal();
        handleCloseAddGLModal();
        // fetchAllGroups();
      }
    } catch (error) {
      console.error("Failed to create group:", error);
      toast.error("Failed to create group");
    }
  };

  const [openAddGLModal, setopenAddGLModal] = useState(false);

  const handleOpenAddGLModal = () => {
    setopenAddGLModal(true);
  };
  const handleCloseAddGLModal = () => {
    setopenAddGLModal(false);
  };

  const handleGroupChange = (event, child) => {
    setSelectedGroupId(child.props.id);
    setSelectedGroupName(event.target.value);
  };

  const handleOptionChange = (event) => {
    const selectedValue = event.target.value;

    setSelectedOption(selectedValue);

    if (selectedValue === "course") {
      setModalData(coursesData);
    } else if (selectedValue === "bundle") {
      setModalData(bundlesData);
    }

    setModalOpen(true);
  };
  const handleItemSelect = (id) => {
    if (selectedOption === "course") {
      setSelectedCourseId(id);
    } else {
      setSelectedBundleId(id);
    }
  };
  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const [ListOfAttachedCourses, setListOfAttachedCourses] = useState([]);
  const getAttachedCoursesOfGroup = async (id) => {
    try {
      setLoading(true);
      const data = await axiosInstance.get(`/get_attached_courses/${id}`);
      setListOfAttachedCourses(data?.data?.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(
        "error getting attached courses to this selected group: ",
        error
      );
    }
  };
  useEffect(() => {
    if (SelectedGroupId) {
      getAttachedCoursesOfGroup(SelectedGroupId);
    }
  }, [SelectedGroupId]);

  const filteredData = modalData.filter((item) => {
    const title =
      selectedOption === "course"
        ? item.courseDetails.title
        : item.bundleDetails.name;
    return title.toLowerCase().includes(searchQuery.toLowerCase());
  });

  return (
    <>
      <div className="w-full min-h-screen flex flex-col bg-[#FAFAFA] justify-start items-center">
        <ToastContainer />

        <div className="w-[100%] flex flex-row gap-5 justify-between items-center mb-4">
          <h1 className="text-[#00486F] font-bold text-[40px] mt-5 mb-5">
            Groups Management
          </h1>
        </div>
        {!SelectedGroupId && (
          <div class="space-y-2 w-full pb-4">
            <div
              role="alert"
              className="bg-blue-100 dark:bg-blue-900 border-l-4 border-[#00486F] dark:border-blue-700 text-[#00486F] dark:text-blue-100 p-2 rounded-lg flex items-center transition duration-300 ease-in-out hover:bg-blue-200 dark:hover:bg-blue-800 transform hover:scale-105 animate-bounce"
            >
              {/* biome-ignore lint/a11y/noSvgWithoutTitle: <explanation> */}
              <svg
                stroke="currentColor"
                viewBox="0 0 24 24"
                fill="none"
                className="h-5 w-5 flex-shrink-0 mr-2 text-blue-600"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M13 16h-1v-4h1m0-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                  strokeWidth="2"
                  strokeLinejoin="round"
                  strokeLinecap="round"
                />
              </svg>
              <p class="text-lg font-semibold">
                Please select a group to continue.
              </p>
            </div>
          </div>
        )}
        <div className="w-[100%]  flex flex-row justify-start gap-5 items-center">
          <FormControl
            sx={{
              width: "20%",
              backgroundColor: "#f9f9f9",
              // borderRadius: "8px",
              boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
              "&:hover": {
                backgroundColor: "#f0f0f0",
              },
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: "#ccc",
                },
                "&:hover fieldset": {
                  borderColor: "#888",
                },
                "&.Mui-focused fieldset": {
                  borderColor: "#00486F",
                  borderWidth: "2px",
                },
              },
            }}
          >
            <InputLabel id="groups">Select Group:</InputLabel>
            <Select
              labelId="groups"
              id="groups"
              value={SelectedGroupName}
              label="Select Group"
              onChange={handleGroupChange}
              sx={{
                "& .MuiSelect-select": {
                  padding: "14px",
                },
                "& .MuiSvgIcon-root": {
                  color: "#888",
                },
              }}
            >
              {AllGroups?.map((group, index) => (
                <MenuItem
                  id={group.id}
                  key={index}
                  value={group.name}
                  sx={{
                    "&:hover": {
                      backgroundColor: "#00486F",
                      color: "#fff",
                    },
                  }}
                >
                  {group.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <Modal
            open={modalOpen}
            onClose={handleCloseModal}
            aria-labelledby="modal-title"
            aria-describedby="modal-description"
          >
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: 600,

                bgcolor: "background.paper",
                boxShadow: 24,
                p: 4,
                display: "flex",
                flexDirection: "column",
                overflow: "hidden",
                borderRadius: "12px",
              }}
            >
              <Typography
                id="modal-title"
                variant="h6"
                component="h2"
                sx={{ mb: 2, textAlign: "center", fontWeight: "bold" }}
              >
                {selectedOption === "course"
                  ? "Available Courses"
                  : "Available Bundles"}
              </Typography>

              <TextField
                variant="outlined"
                placeholder="Search..."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                sx={{
                  mb: 2,
                  width: "100%",
                }}
              />

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  padding: "12px 16px",
                  borderBottom: "2px solid #ddd",
                  backgroundColor: "#f9f9f9",
                  position: "sticky",
                  top: 0,
                  zIndex: 1,
                }}
              >
                <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
                  Name
                </Typography>
                <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
                  Available Seats
                </Typography>
              </Box>

              <Paper
                sx={{
                  flex: 1,
                  maxHeight: "calc(80vh - 160px)",
                  overflowY: "auto",
                  p: 2,
                  mb: 2,
                  borderRadius: "8px",
                }}
              >
                <Grid container spacing={2}>
                  {filteredData.map((item) => {
                    const itemId =
                      selectedOption === "course"
                        ? item.courseDetails.id
                        : item.bundle_id;
                    const isSelected =
                      selectedOption === "course"
                        ? SelectedCourseId === itemId
                        : SelectedBundleId === itemId;

                    return (
                      <Grid item xs={12} key={itemId}>
                        <Card
                          sx={{
                            boxShadow: isSelected
                              ? "0px 4px 10px rgba(0, 0, 0, 0.2)"
                              : "0px 4px 6px rgba(0, 0, 0, 0.1)",
                            border: isSelected
                              ? "2px solid #02496F"
                              : "1px solid #ddd",
                            backgroundColor: "white",

                            fontSize: { xs: "10px", sm: "12px" }, // Control text size for responsiveness
                          }}
                        >
                          <CardActionArea
                            onClick={() => handleItemSelect(itemId)}
                          >
                            <CardContent
                              sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                padding: "10px", // Reducing padding for a more compact look
                                height: { xs: "48px", sm: "56px" }, // Matching height from Uiverse.io example
                              }}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  gap: 1,
                                }}
                              >
                                <Radio
                                  checked={isSelected}
                                  onChange={() => handleItemSelect(itemId)}
                                  sx={{ p: 0, mr: 1 }} // Adjusting margin for a tighter fit
                                />
                                <Typography
                                  variant="subtitle2" // Smaller font variant for compactness
                                  sx={{
                                    color: "black",
                                    fontSize: { xs: "10px", sm: "12px" },
                                  }} // Text color and size
                                >
                                  {selectedOption === "course"
                                    ? item.courseDetails.title
                                    : item.bundleDetails.name}
                                </Typography>
                              </Box>
                              <Typography
                                variant="caption"
                                sx={{
                                  color: "text.secondary",
                                  fontSize: { xs: "10px", sm: "12px" },
                                }}
                              >
                                {item.seats}
                              </Typography>
                            </CardContent>
                          </CardActionArea>
                        </Card>
                      </Grid>
                    );
                  })}
                </Grid>
              </Paper>
            </Box>
          </Modal>
        </div>
        {loading && <Loader />}
        {!loading && SelectedGroupId && (
          <Box sx={{ width: "100%", mt: 5, mb: 5 }}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
                sx={{
                  "& .MuiTabs-indicator": {
                    backgroundColor: "#02496F",
                  },
                }}
              >
                {["Subscriber", "Attached Courses"].map((label, index) => (
                  <Tab
                    key={index}
                    label={label}
                    {...a11yProps(index)}
                    sx={{
                      "&.Mui-selected": {
                        color: "#02496F",
                        fontWeight: "bold",
                      },
                      "&:hover": {
                        color: "#00486F",
                        fontWeight: "bold",
                      },
                    }}
                  />
                ))}
              </Tabs>
            </Box>

            <TabPanel value={value} index={0}>
              <Subscriber
                isGroupLeaderDashboard={isGroupLeaderDashboard}
                g_id={SelectedGroupId}
              />
            </TabPanel>

            <TabPanel value={value} index={1}>
              <Box sx={{ width: "100%", my: 4 }}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                    gap: 2,
                  }}
                >
                  <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                    <Typography
                      variant="h4"
                      component="h1"
                      sx={{
                        fontWeight: "bold",
                        // color: theme.palette.primary.main,
                        fontSize: { xs: "1.5rem", sm: "2rem" },
                      }}
                    >
                      Attached Courses
                    </Typography>
                    <Chip
                      icon={<PeopleIcon />}
                      label={`${ListOfAttachedCourses?.length} ${
                        ListOfAttachedCourses?.length === 1
                          ? "Courses"
                          : "Courses"
                      }`}
                      sx={{
                        bgcolor: "#02496F",
                        color: "white",
                        fontWeight: "bold",
                        fontSize: "0.9rem",
                        // height: "auto",
                        p: "2px",
                        "& .MuiChip-icon": {
                          color: "white",
                          fontSize: "1.2rem",
                        },
                      }}
                    />
                  </Box>
                </Box>
              </Box>
              <Box sx={{ width: "100%", mt: 2, mb: 2 }}>
                {ListOfAttachedCourses.length > 0 ? (
                  <Grid container spacing={2}>
                    {ListOfAttachedCourses.map((course, index) => (
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        md={4}
                        lg={3}
                        xl={2.4}
                        key={index}
                      >
                        <Link
                          to={`/groupleader_dashboard/course_details?id=${course.id}`}
                        >
                          <StyledCard>
                            <StyledCardContent>
                              <Typography
                                variant="h6"
                                component="h2"
                                gutterBottom
                              >
                                {course.title}
                              </Typography>
                              <Typography variant="body2" color="textSecondary">
                                Course ID: {course.id}
                              </Typography>
                            </StyledCardContent>
                          </StyledCard>
                        </Link>
                      </Grid>
                    ))}
                  </Grid>
                ) : (
                  <Paper
                    elevation={1}
                    sx={{
                      p: 2,
                      bgcolor: "background.default",
                    }}
                  >
                    <Typography variant="body2" color="text.secondary">
                      No Attached Courses.
                    </Typography>
                  </Paper>
                )}
              </Box>
            </TabPanel>
          </Box>
        )}

        <Dialog open={addgroupmodal} onClose={handleCloseGroupModal} fullWidth>
          <DialogTitle
            sx={{
              marginBottom: "20px",
              backgroundColor: "#00486f",
              color: "white",
              fontWeight: "bold",
            }}
          >
            Add Group
          </DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              required
              margin="dense"
              id="groupname"
              name="groupname"
              label="Group Name"
              type="text"
              fullWidth
              variant="standard"
              value={formData.groupname}
              onChange={(e) =>
                setFormData({ ...formData, groupname: e.target.value })
              }
            />
          </DialogContent>
          <DialogContent>
            <FormLabel id="signinType">Select Signin Type:</FormLabel>
            <RadioGroup
              aria-labelledby="signinType"
              defaultValue="with_pwd"
              name="signinType"
              value={formData.signinType}
              onChange={(e) =>
                setFormData({ ...formData, signinType: e.target.value })
              }
            >
              <FormControlLabel
                value="pwd_less"
                control={<Radio />}
                label="Password Less"
              />
              <FormControlLabel
                value="with_pwd"
                control={<Radio />}
                label="With Password"
              />
            </RadioGroup>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleCloseGroupModal}
              sx={{
                width: "84px",
                color: "white",
                backgroundColor: "#d32f2f",
                "&:hover": {
                  backgroundColor: "#b71c1c",
                },
              }}
              variant="contained"
            >
              Cancel
            </Button>
            <Button
              sx={{
                width: "84px",
                color: "white",
                backgroundColor: "#02496F",
                "&:hover": {
                  backgroundColor: "#023A5A",
                },
              }}
              onClick={handleOpenAddGLModal}
            >
              Add
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog open={openAddGLModal} onClose={handleCloseAddGLModal} fullWidth>
          <DialogTitle
            sx={{
              marginBottom: "20px",
              backgroundColor: "#00486f",
              color: "white",
              fontWeight: "bold",
            }}
          >
            Add Group Leader
          </DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              required
              margin="dense"
              id="gl_firstname"
              name="gl_firstname"
              label="First Name"
              type="text"
              fullWidth
              variant="standard"
              value={formData.gl_firstname}
              onChange={(e) =>
                setFormData({ ...formData, gl_firstname: e.target.value })
              }
            />
            <TextField
              autoFocus
              required
              margin="dense"
              id="gl_lastname"
              name="gl_lastname"
              label="Last Name"
              type="text"
              fullWidth
              variant="standard"
              value={formData.gl_lastname}
              onChange={(e) =>
                setFormData({ ...formData, gl_lastname: e.target.value })
              }
            />
            <TextField
              autoFocus
              required
              margin="dense"
              id="gl_email"
              name="gl_email"
              label="Email"
              type="email"
              fullWidth
              variant="standard"
              value={formData.gl_email}
              onChange={(e) =>
                setFormData({ ...formData, gl_email: e.target.value })
              }
            />
          </DialogContent>

          <DialogActions>
            <Button onClick={handleCloseAddGLModal}>Cancel</Button>
            <Button onClick={handleGroupSubmit}>Add</Button>
          </DialogActions>
        </Dialog>
      </div>
    </>
  );
};

export default GroupLeaderGroupManagmentPage;
