import React, { useMemo, useEffect, useState } from "react";
import {
  Button,
  TextField,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Box,
  InputAdornment,
} from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import EmailIcon from "@mui/icons-material/Email";
import { MaterialReactTable } from "material-react-table";
import axiosInstance from "../../utils/axiosConfig";
import axios from "axios";
import { GoPhishAccountAPIKey, GoPhishPublicURL } from "../../utils/constants";

const Campaigns = () => {
  const [allCampaigns, setallCampaigns] = useState([]);
  const [open, setOpen] = useState(false);
  const [allEmailTemplates, setallEmailTemplates] = useState([]);
  const [allLandingPages, setallLandingPages] = useState([]);
  const [allSMTPS, setallSMTPS] = useState([]);
  const [formValues, setFormValues] = useState({
    campaignName: "",
    emailTemplate: "",
    landingPage: "",
    url: "",
    sendEmailsBy: null,
    sendingProfile: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const handleClose = () => {
    setOpen(false);
  };

  const getEmailTemplatesFromGoPhish = async () => {
    try {
      const resp = await axios.get(
        `${GoPhishPublicURL}/api/templates/?api_key=${GoPhishAccountAPIKey}`
      );
      setallEmailTemplates(resp.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getLandingPagesFromGoPhish = async () => {
    try {
      const resp = await axios.get(
        `${GoPhishPublicURL}/api/pages/?api_key=${GoPhishAccountAPIKey}`
      );
      setallLandingPages(resp.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getSendingProfileFromGoPhish = async () => {
    try {
      const resp = await axios.get(
        `${GoPhishPublicURL}/api/smtp/?api_key=${GoPhishAccountAPIKey}`
      );
      setallSMTPS(resp.data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleOpen = () => {
    setOpen(true);
    getEmailTemplatesFromGoPhish();
    getLandingPagesFromGoPhish();
    getSendingProfileFromGoPhish();
  };

  const CampaignsColumns = useMemo(
    () => [
      {
        accessorKey: "id",
        header: "Id",
        size: 100,
        Cell: ({ cell }) => (
          <Box>
            {cell.getValue().length > 100
              ? cell.getValue().slice(0, 100) + "..."
              : cell.getValue()}
          </Box>
        ),
      },
      {
        accessorKey: "name",
        header: "Name",
        size: 100,
        Cell: ({ cell }) => (
          <Box>
            {cell.getValue().length > 100
              ? cell.getValue().slice(0, 100) + "..."
              : cell.getValue()}
          </Box>
        ),
      },
      {
        accessorKey: "template",
        header: "Email Template",
        size: 100,
        Cell: ({ cell }) => (
          <Box>
            {cell.getValue().length > 100
              ? cell.getValue().slice(0, 100) + "..."
              : cell.getValue()}
          </Box>
        ),
      },
      {
        accessorKey: "url",
        header: "URL",
        size: 100,
        Cell: ({ cell }) => (
          <Box>
            {cell.getValue().length > 100
              ? cell.getValue().slice(0, 100) + "..."
              : cell.getValue()}
          </Box>
        ),
      },
      {
        accessorKey: "page",
        header: "Landing Page",
        size: 100,
        Cell: ({ cell }) => (
          <Box>
            {cell.getValue().length > 100
              ? cell.getValue().slice(0, 100) + "..."
              : cell.getValue()}
          </Box>
        ),
      },
      {
        accessorKey: "smtp",
        header: "Sending Profile",
        size: 100,
        Cell: ({ cell }) => (
          <Box>
            {cell.getValue().length > 100
              ? cell.getValue().slice(0, 100) + "..."
              : cell.getValue()}
          </Box>
        ),
      },
      {
        accessorKey: "sendbydate",
        header: "Send By Date",
        size: 100,
        Cell: ({ cell }) => (
          <Box>
            {cell.getValue().length > 100
              ? cell.getValue().slice(0, 100) + "..."
              : cell.getValue()}
          </Box>
        ),
      },
    ],
    []
  );

  const getAllCampaigns = async () => {
    try {
      const response = await axiosInstance.get("/campaigns/all");
      if (response.data.data) {
        setallCampaigns(response.data.data);
      } else {
        setallCampaigns([]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleAddCampaign = async () => {
    try {
      const response = await axiosInstance.post("/campaigns/add", {
        name: formValues.campaignName,
        template: formValues.emailTemplate,
        url: formValues.url,
        page: formValues.landingPage,
        smtp: formValues.sendingProfile,
        // "sendbydate": formValues.sendEmailsBy
      });
      if (response.status === 201) {
        toast.success("Campaign draft created successfully!");
        getAllCampaigns();
        handleClose();
      }
    } catch (error) {
      console.log("Error while adding campaign: ", error);
    }
  };

  useEffect(() => {
    getAllCampaigns();
  }, []);

  return (
    <>
      <div className="w-full flex flex-col justify-start items-center">
        <ToastContainer />

        <div className="w-[90%] flex flex-row gap-5 justify-between items-center">
          <h1 className="text-[#00486F] font-bold text-[30px] mt-5 mb-5">
            All Campaigns
          </h1>
          <Button
            sx={{
              fontWeight: "bold",
              textTransform: "none",
              px: 3,
              py: 1,
              borderRadius: 2,
              bgcolor: "#299aa1",
              boxShadow: 2,
              "&:hover": {
                bgcolor: "#00A3AE",
                boxShadow: 4,
              },
            }}
            variant="contained"
            onClick={handleOpen}
          >
            New Campaign
          </Button>
        </div>

        {/* Wrapping table inside a scrollable container */}
        <div className="w-[90%] flex flex-col justify-center items-center">
          <div style={{ width: "100%", overflowX: "auto" }}>
            {" "}
            {/* Enables horizontal scrolling */}
            <MaterialReactTable
              columns={CampaignsColumns}
              data={allCampaigns}
              muiTopToolbarProps={{
                sx: {
                  backgroundColor: "#e1ecf0",
                  color: "#00486F",
                  "& .MuiSvgIcon-root": {
                    color: "#FFFFFF", // Set all icons to white in the toolbar
                  },
                  "& .MuiButtonBase-root": {
                    color: "#00486F", // Set buttons/icons (like filters, export) to white
                  },
                },
              }}
              muiTablePaperProps={{
                sx: {
                  borderRadius: "12px",
                  overflow: "hidden",
                },
              }}
              muiTablePaginationProps={{
                sx: {
                  backgroundColor: "#e1ecf0",
                  color: "#00486F",
                  "& .MuiSvgIcon-root": {
                    color: "#00486F", // Set pagination icons to white
                  },
                  "& .MuiButtonBase-root": {
                    color: "#00486F", // Set pagination buttons to white
                  },
                },
              }}
            />
          </div>
        </div>

        {/* Dialog for creating a new campaign */}
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle
            sx={{
              marginBottom: "20px",
              backgroundColor: "#00486f",
              color: "white",
              fontWeight: "bold",
            }}
          >
            Create a New Campaign
          </DialogTitle>
          <DialogContent>
            <TextField
              margin="dense"
              name="campaignName"
              label="Name"
              type="text"
              fullWidth
              value={formValues.campaignName}
              onChange={handleInputChange}
            />
            <FormControl fullWidth margin="dense">
              <InputLabel>Email Template</InputLabel>
              <Select
                name="emailTemplate"
                value={formValues.emailTemplate}
                onChange={handleInputChange}
              >
                {allEmailTemplates &&
                  allEmailTemplates.map((emailtemp, index) => (
                    <MenuItem key={index} value={emailtemp.name}>
                      {emailtemp.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            <FormControl fullWidth margin="dense">
              <InputLabel>Landing Page</InputLabel>
              <Select
                name="landingPage"
                value={formValues.landingPage}
                onChange={handleInputChange}
              >
                {allLandingPages &&
                  allLandingPages.map((landingpage, index) => (
                    <MenuItem key={index} value={landingpage.name}>
                      {landingpage.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            <TextField
              margin="dense"
              name="url"
              label="URL"
              type="url"
              fullWidth
              value={formValues.url}
              onChange={handleInputChange}
              placeholder="http://192.168.1.1"
            />
            <FormControl fullWidth margin="dense">
              <InputLabel>Sending Profile</InputLabel>
              <Select
                name="sendingProfile"
                value={formValues.sendingProfile}
                onChange={handleInputChange}
              >
                {allSMTPS &&
                  allSMTPS.map((smtp, index) => (
                    <MenuItem key={index} value={smtp.name}>
                      {smtp.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </DialogContent>

          <DialogActions
            sx={{
              padding: "16px",
              display: "flex",

              // justifyContent: "space-between", // Space the buttons apart for a better UI
            }}
          >
            <Button
              onClick={handleClose}
              sx={{
                width: "84px",
                color: "white",
                backgroundColor: "#d32f2f",
                "&:hover": {
                  backgroundColor: "#b71c1c",
                },
              }}
              variant="contained"
            >
              Cancel
            </Button>

            <Button
              onClick={handleAddCampaign}
              sx={{
                width: "84px",
                color: "white",
                backgroundColor: "#02496F",
                "&:hover": {
                  backgroundColor: "#023A5A",
                },
              }}
              variant="contained"
            >
              Create
            </Button>
          </DialogActions>
          {/* <DialogActions>
						<Button onClick={handleClose}>Close</Button>
						<Button onClick={handleAddCampaign} variant="contained">
							Create
						</Button>
					</DialogActions> */}
        </Dialog>
      </div>
    </>
  );
};

export default Campaigns;
