import React, { useState, useEffect } from "react";
import axiosInstance from "../../utils/axiosConfig";
import { useAuth } from "../../AuthContext";
import courseicon from "../../assets/course.png";
import { Link } from "react-router-dom";
import Avatar from "@mui/material/Avatar";
import { deepOrange } from "@mui/material/colors";
import { Grid, Typography } from "@mui/material";
const Loader = () => {
  return (
    <div className="w-full h-full flex items-center justify-center mt-4 opacity-75 z-50">
      <div
        class="loader border-t-2 rounded-full border-gray-500 bg-gray-300 animate-spin
aspect-square w-16 flex justify-center items-center text-yellow-700"
      ></div>
    </div>
  );
};
const Courses = () => {
  const { currentUser } = useAuth();
  const [Courses, setCourses] = useState([]);
  const [loading, setLoading] = useState(false);

  const getAllCourses = async () => {
    try {
      setLoading(true);
      const response = await axiosInstance.post("/my_courses", {
        id: currentUser.id,
      });
      setLoading(false);
      setCourses(response.data.courseDetails);
    } catch (error) {
      setLoading(false);
      console.log("Error fetching group courses: ", error.message);
    }
  };

  useEffect(() => {
    if (currentUser) {
      getAllCourses();
    }
  }, [currentUser]);
  return (
    <>
      {/* <div className="w-full max-w-2xl mx-auto bg-white shadow-lg rounded-lg overflow-hidden">
      <div className="h-48 bg-gradient-to-r from-purple-900 to-indigo-900 relative">
        <div className="absolute -bottom-12 left-4">
          <div className="w-24 h-24 bg-white rounded-full flex items-center justify-center">
            <User size={48} className="text-gray-400" />
          </div>
        </div>
      </div>
      <div className="pt-16 px-4 pb-4">
        <h2 className="text-2xl font-semibold">User Name Here</h2>
        <p className="text-sm text-gray-600">12 Points</p>
        <p className="text-sm text-gray-500">Joined Apr 2024 · Active now</p>
      </div>
      <div className="border-t border-gray-200">
        <nav className="flex">
          <a href="#modules" className="px-4 py-2 text-sm font-medium text-blue-600 border-b-2 border-blue-600">Modules</a>
          <a href="#awards" className="px-4 py-2 text-sm font-medium text-gray-500">Awards</a>
          <a href="#achievements" className="px-4 py-2 text-sm font-medium text-gray-500">Achievements</a>
        </nav>
      </div>
      <div className="p-4">
        <div className="bg-white rounded-lg shadow">
          <img src="/api/placeholder/400/225" alt="Course thumbnail" className="w-full h-32 object-cover rounded-t-lg" />
          <div className="p-4">
            <p className="text-xs text-gray-500">1 lesson</p>
            <h3 className="text-lg font-semibold mt-1">Password Security</h3>
            <div className="flex items-center mt-2">
              <div className="w-full bg-gray-200 rounded-full h-2.5">
                <div className="bg-blue-600 h-2.5 rounded-full" style={{width: '0%'}}></div>
              </div>
              <span className="text-xs text-gray-500 ml-2">0% Complete</span>
            </div>
            <p className="text-xs text-gray-500 mt-1">0/7 Steps</p>
          </div>
        </div>
      </div>
    </div> */}
      <div className="w-full flex flex-col  justify-start items-start px-4">
        {/* User Info Section */}

        <div className="w-full  mx-auto bg-white shadow-lg rounded-lg overflow-hidden">
          <div className="relative">
            <img
              src="/back.jpeg"
              alt="Background"
              className="w-full h-48 object-cover"
            />
            <div className="absolute top-[8.7rem] left-[9px]">
              <Avatar
                sx={{ width: 94, height: 94, bgcolor: deepOrange[500] }}
                alt="Remy Sharp"
                src="/broken-image.jpg"
              />
            </div>
            <div className="h-20 px-4 pb-4">
              <h2 className="text-2xl ml-24 mt-2 font-semibold">
                {currentUser.firstname || "User Name Here"}
              </h2>
            </div>
          </div>
        </div>
        {/* <div className="w-full mb-2">
          {" "}
        
          <img
            src="/back.jpeg"
            alt="Display"
            className="w-full h-[12rem] object-cover rounded-lg" 
          />
        </div>
        <div className="flex items-start ml-2 w-full">
          <div className="flex flex-col items-center">
            <div className="relative">
              <div className="absolute top-0 left-0">
                <Avatar
                  sx={{ width: 74, height: 74, bgcolor: deepOrange[500] }} 
                  alt="Remy Sharp"
                  src="/broken-image.jpg"
                />
              </div>
            </div>
          </div>
          <div className="pt-4 pb-4 flex flex-col justify-center ml-20">
            {" "}
         
            <h2 className="text-2xl font-semibold m-2 text-left">
              {currentUser?.firstname}
            </h2>{" "}
           
          </div>
        </div> */}

        {/* Navigation Section */}
        <div className="border-t border-gray-200 w-full">
          <nav className="flex">
            <a
              href="#"
              className="px-4 py-2 text-sm font-medium text-blue-600 border-b-2 border-blue-600"
            >
              Modules
            </a>
          </nav>
        </div>

        {/* Courses Section */}
        {loading ? (
          <Loader />
        ) : (
          <div className="w-full flex flex-col justify-center items-start mt-5">
            <h1 className="text-[#00486F] font-bold text-[20px] mb-5">
              All Courses
            </h1>
            {/* <div className="w-full grid md:grid-cols-2 grid-cols-1 lg:grid-cols-4 justify-center gap-2 items-center flex-wrap mt-8">
              {Courses &&
                Courses.map((course, index) => (
                  <Link
                    key={index}
                    to={`/subscriber_dashboard/getting_started?course_id=${course.id}`}
                  >
                    <CourseCard data={course} />
                  </Link>
                ))}
            </div> */}
            <Grid container spacing={2}>
              {Courses?.map((course, index) => (
                <Grid
                  item
                  xs={12} // 1 card for extra small screens (below 600px)
                  sm={12} // 1 card for small screens (600px - 800px)
                  md={6} // 2 cards for medium screens (800px - 1200px)
                  lg={4} // 3 cards for large screens (1200px - 1700px)
                  xl={3} // 4 cards for extra-large screens (1700px and above)
                  key={index}
                >
                  <Link
                    key={index}
                    to={`/subscriber_dashboard/getting_started?course_id=${course.id}`}
                  >
                    <CourseCard data={course} />
                  </Link>
                </Grid>
              ))}
            </Grid>
          </div>
        )}
      </div>
    </>
  );
};

const truncateDescription = (description, maxLength) => {
  return description.length > maxLength
    ? description.slice(0, maxLength) + "..."
    : description;
};

export const CourseCard = ({ data }) => {
  return (
    <>
      <div className="w-full bg-white border border-gray-200 rounded-lg shadow flex flex-col h-full">
        <img
          className="rounded-t-lg w-full h-[150px] sm:h-[180px] md:h-[200px] lg:h-[250px] object-cover"
          src={courseicon}
          alt="Course Icon"
        />

        <div className="p-5 flex-1 flex flex-col justify-between">
          <div>
            <Typography
              variant="h5"
              component="h2"
              gutterBottom
              className="truncate"
              style={{
                maxHeight: "2.6em",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {data.title}
            </Typography>

            <Typography
              variant="body2"
              color="textSecondary"
              className="mb-4 line-clamp-3"
              style={{
                maxHeight: "4.5em", // Allows for 3 lines of text
                overflow: "hidden",
                display: "-webkit-box",
                WebkitLineClamp: 3,
                WebkitBoxOrient: "vertical",
              }}
            >
              {truncateDescription(data.description, 100)}
            </Typography>
          </div>

          {/* Add to Cart Button */}
        </div>
      </div>
    </>
  );
};

export default Courses;
