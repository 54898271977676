const { FaUsersLine } = require("react-icons/fa6");
const { MdLibraryBooks, MdAutoGraph } = require("react-icons/md");

export const ReportsSection = ({
  numOfUsers,
  numOfGroups,
  numOfcampaigns,
  groupleader,
}) => {
  return (
    <div className="w-full bg-white shadow-lg rounded-lg p-8 mb-8">
      <h2 className="text-3xl font-bold text-blue-900 mb-6">Overivew</h2>
      <div className="flex flex-wrap justify-between items-center gap-8">
        <ReportCard title="Users" value={numOfUsers} icon={<FaUsersLine />} />
        {groupleader && (
          <ReportCard
            title="Active Campaigns"
            value={numOfGroups}
            icon={<MdLibraryBooks />}
          />
        )}
        {!groupleader && (
          <ReportCard
            title="Groups"
            value={numOfGroups}
            icon={<MdLibraryBooks />}
          />
        )}
        {groupleader && (
          <ReportCard
            title="Training Modules"
            value={numOfcampaigns}
            icon={<MdAutoGraph />}
          />
        )}
        {!groupleader && (
          <ReportCard
            title="Campaigns"
            value={numOfcampaigns}
            icon={<MdAutoGraph />}
          />
        )}
      </div>
    </div>
  );
};

export const ReportCard = ({ title, value, icon }) => {
  return (
    <div className="flex-1 min-w-[200px] bg-gray-50 rounded-lg p-6 shadow-md transition-all duration-300 hover:shadow-xl">
      <div className="flex items-center justify-between mb-4">
        <span className="text-4xl text-[#00486F]">{icon}</span>
        <span className="text-3xl font-bold text-[#00486F]">{value}</span>
      </div>
      <h3 className="text-xl text-gray-700">{title}</h3>
    </div>
  );
};
