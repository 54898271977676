import React, { useState } from "react";
import axiosInstance from "../../../utils/axiosConfig";
import { useAuth } from "../../../AuthContext";
import { useNavigate } from "react-router-dom";
import {
	Box,
	Button,
	Card,
	CardContent,
	TextField,
	Typography,
	Container,
	ThemeProvider,
	createTheme,
	CssBaseline,
} from "@mui/material";
import { Email, VpnKey, SupervisorAccount } from "@mui/icons-material";

const GroupLeaderOTPLogin = () => {
	const navigate = useNavigate();
	const { login } = useAuth();

	const [email, setEmail] = useState("");
	const [otp, setOtp] = useState("");
	const [isOtpSent, setIsOtpSent] = useState(false);
	const [message, setMessage] = useState("");

	const handleEmailChange = (e) => {
		setEmail(e.target.value);
	};

	const handleOtpChange = (e) => {
		setOtp(e.target.value);
	};

	const handleSendOtp = async () => {
		try {
			const response = await axiosInstance.post(
				"/v1/auth/pwdless/groupleader_loginotp",
				{ email },
			);
			if (response.status === 200 && response.data.success === true) {
				setIsOtpSent(true);
				setMessage("OTP sent successfully!");
			} else {
				setMessage(response.data.message);
			}
		} catch (error) {
			setMessage("Error sending OTP. Please try again.");
			console.error("Error sending OTP:", error);
		}
	};

	const handleVerifyOtp = async () => {
		try {
			const response = await axiosInstance.post("/otp/verify-pwdless-otp", {
				email,
				otpCode: otp,
				userType: "groupleader",
			});
			if (response.status === 200 && response.data.success) {
				setMessage("Successfully logged in!");
				login(response.data?.user);
				navigate("/groupleader_dashboard");
			} else {
				setMessage(response.data.message || "Invalid OTP or OTP expired.");
			}
		} catch (error) {
			setMessage("Error verifying OTP. Please try again.");
			console.error("Error verifying OTP:", error);
		}
	};

	return (
		<>
			<div className="bg-[#f8f4f3] min-h-screen flex justify-center items-center">
				<CssBaseline />
				<Container
					component="main"
					maxWidth="xs"
					sx={{
						display: "flex",
						flexDirection: "column",
						alignItems: "center",
						justifyContent: "center", // Center vertically
						minHeight: "100vh", // Ensure it covers the whole viewport
					}}
				>
					<div className="mb-8">
						<h2 className="font-bold text-3xl text-center">
							Group Leader{" "}
							<span className="bg-[#00486F] text-white px-2 rounded-md">
								PORTAL
							</span>
						</h2>
					</div>
					<Card raised sx={{ width: "100%", bgcolor: "background.paper" }}>
						<CardContent
							sx={{
								display: "flex",
								flexDirection: "column",
								alignItems: "center",
								p: 4,
							}}
						>
							<SupervisorAccount
								sx={{ fontSize: 40, mb: 2, color: "primary.main" }}
							/>
							<Typography component="h1" variant="h5" gutterBottom>
								OTP Login
							</Typography>
							<Box component="form" sx={{ mt: 1, width: "100%" }}>
								{!isOtpSent ? (
									<>
										<TextField
											margin="normal"
											required
											fullWidth
											id="email"
											label="Email Address"
											name="email"
											autoComplete="email"
											autoFocus
											value={email}
											onChange={handleEmailChange}
											InputProps={{
												startAdornment: <Email color="action" sx={{ mr: 1 }} />,
											}}
										/>
										<Button
											fullWidth
											variant="contained"
											sx={{
												mt: 3,
												mb: 2,
												backgroundColor: "#00486F", // Set background color
												"&:hover": {
													backgroundColor: "#00314D", // Darken the background color on hover
												},
												color: "white", // Set text color to white
											}}
											onClick={handleSendOtp}
										>
											Send OTP
										</Button>
									</>
								) : (
									<>
										<TextField
											margin="normal"
											required
											fullWidth
											id="otp"
											label="Enter OTP"
											name="otp"
											autoFocus
											value={otp}
											onChange={handleOtpChange}
											InputProps={{
												startAdornment: (
													<VpnKey color="action" sx={{ mr: 1 }} />
												),
											}}
										/>
										<Button
											fullWidth
											variant="contained"
											sx={{
												mt: 3,
												mb: 2,
												backgroundColor: "#00486F", // Set background color
												"&:hover": {
													backgroundColor: "#00314D", // Darken the background color on hover
												},
												color: "white", // Set text color to white
											}}
											onClick={handleVerifyOtp}
										>
											Verify & Login
										</Button>
									</>
								)}
								{message && (
									<Typography
										color={message.includes("verified") ? "success" : "error"}
										variant="body2"
										sx={{ mt: 2, textAlign: "center" }}
									>
										{message}
									</Typography>
								)}
							</Box>
						</CardContent>
					</Card>
				</Container>
			</div>
		</>
	);
};

export default GroupLeaderOTPLogin;
