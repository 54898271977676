import { Box, Tab, Tabs, Typography } from "@mui/material";
import GroupLeader from "./sections/GroupLeader";
import Contributor from "./sections/Contributor";
import Subscriber from "./sections/Subscriber";
import { useState } from "react";

const TabPanel = ({ children, value, index, ...other }) => {
	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box sx={{ p: 3 }}>
					<Typography>{children}</Typography>
				</Box>
			)}
		</div>
	);
};
const a11yProps = (index) => {
	return {
		id: `simple-tab-${index}`,
		"aria-controls": `simple-tabpanel-${index}`,
	};
};
const AllUsers = () => {
	const [value, setValue] = useState(0);

	const handleChange = (event, newValue) => {
		setValue(newValue);
	};
	return (
		<div className="w-full flex flex-col  justify-start items-center">
			<div className="w-[100%] flex flex-row gap-5 justify-between items-center">
				<h1 className="text-[#00486F] font-bold text-[30px] mt-5 mb-5">
					All Users
				</h1>
			</div>
			<Box sx={{ width: "100%", marginTop: 4 }}>
				<>
					<Box sx={{ borderBottom: 1, borderColor: "divider" }}>
						<Tabs
							value={value}
							onChange={handleChange}
							aria-label="basic tabs example"
							sx={{
								"& .MuiTabs-indicator": {
									backgroundColor: "#02496F",
								},
							}}
						>
							{["Contributor", "Group Leader", "Subscriber"].map(
								(label, index) => (
									<Tab
										key={index}
										label={label}
										{...a11yProps(index)}
										sx={{
											"&.Mui-selected": {
												color: "#02496F",
												fontWeight: "bold",
											},
											"&:hover": {
												color: "#00486F",
												fontWeight: "bold",
											},
										}}
									/>
								),
							)}
						</Tabs>
					</Box>

					<TabPanel value={value} index={0}>
						<Contributor />
					</TabPanel>

					<TabPanel value={value} index={1}>
						<GroupLeader admin={true} />
					</TabPanel>

					<TabPanel value={value} index={2}>
						<Subscriber admin={true} />
					</TabPanel>
				</>
			</Box>
		</div>
	);
};
export default AllUsers;
