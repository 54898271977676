import React, { useMemo, useEffect, useState } from "react";
import {
  Button,
  TextField,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Chip,
  Box,
  OutlinedInput,
  InputAdornment,
} from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CloseIcon from "@mui/icons-material/Close";
import EmailIcon from "@mui/icons-material/Email";
import { MaterialReactTable } from "material-react-table";
import axiosInstance from "../../utils/axiosConfig";
import { useAuth } from "../../AuthContext";
import axios from "axios";
import { GoPhishAccountAPIKey, GoPhishPublicURL } from "../../utils/constants";

const Campaigns = () => {
  const { currentUser } = useAuth();
  const [allCampaigns, setallCampaigns] = useState([]);
  const [allGroups, setAllGroups] = useState([]);
  const [open, setOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState({
    name: "",
    template: "",
    page: "",
    url: "",
    launchDate: "",
    sendbydate: "",
    smtp: "",
    groups: [],
  });

  const fetchAllGroups = async () => {
    try {
      const resp = await axiosInstance.get(`/groups/all/${currentUser.email}`);
      setAllGroups(resp.data.groups);
    } catch (error) {
      console.log("Error fetching all groups!");
    }
  };

  const handleView = (row) => {
    setSelectedRow({
      ...row,
      groups: row.groups || [],
    });
    setOpen(true);
    fetchAllGroups();
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSelectChange = (event) => {
    const { value } = event.target;
    setSelectedRow((prevRow) => ({ ...prevRow, groups: value }));
  };

  const handleDelete = (groupToDelete) => (event) => {
    event.stopPropagation();
    setSelectedRow((prevRow) => ({
      ...prevRow,
      groups: prevRow.groups.filter((group) => group !== groupToDelete),
    }));
  };

  const handleLaunchCampaign = async () => {
    const userEmail = currentUser.email;

    // Embed the original name, user email, and group names into the campaign name
    const campaignNameWithUser = `${
      selectedRow.name
    }, email: ${userEmail}, groups: ${selectedRow.groups.join(", ")}`;
    console.log("groups", selectedRow.groups.join(", "));
    const apiData = {
      name: campaignNameWithUser,
      template: { name: selectedRow.template },
      url: selectedRow.url,
      page: { name: selectedRow.page },
      smtp: { name: selectedRow.smtp },
      launch_date: new Date(selectedRow.launchDate).toISOString(),
      send_by_date: selectedRow.sendbydate
        ? new Date(selectedRow.sendbydate).toISOString()
        : null, // Convert to ISO format if present
      groups: selectedRow.groups.map((groupName) => ({
        name: groupName,
      })),
    };

    try {
      const resp = await axios.post(
        `${GoPhishPublicURL}/api/campaigns/?api_key=${GoPhishAccountAPIKey}`,
        apiData
      );
      if (resp.status === 201) {
        toast.success("Campaign launched successfully!");
        setOpen(false);
      }
    } catch (error) {
      console.log(error);
      toast.error("Failed to launch campaign!");
      setOpen(false);
    }
  };

  const CampaignsColumns = useMemo(
    () => [
      {
        accessorKey: "id",
        header: "Id",
        size: 100,
        Cell: ({ cell }) => (
          <Box>
            {cell.getValue().length > 100
              ? cell.getValue().slice(0, 100) + "..."
              : cell.getValue()}
          </Box>
        ),
      },
      {
        accessorKey: "name",
        header: "Name",
        size: 100,
        Cell: ({ cell }) => (
          <Box>
            {cell.getValue().length > 100
              ? cell.getValue().slice(0, 100) + "..."
              : cell.getValue()}
          </Box>
        ),
      },
      {
        accessorKey: "template",
        header: "Email Template",
        size: 100,
        Cell: ({ cell }) => (
          <Box>
            {cell.getValue().length > 100
              ? cell.getValue().slice(0, 100) + "..."
              : cell.getValue()}
          </Box>
        ),
      },
      {
        accessorKey: "url",
        header: "URL",
        size: 100,
        Cell: ({ cell }) => (
          <Box>
            {cell.getValue().length > 100
              ? cell.getValue().slice(0, 100) + "..."
              : cell.getValue()}
          </Box>
        ),
      },
      {
        accessorKey: "page",
        header: "Landing Page",
        size: 100,
        Cell: ({ cell }) => (
          <Box>
            {cell.getValue().length > 100
              ? cell.getValue().slice(0, 100) + "..."
              : cell.getValue()}
          </Box>
        ),
      },
      {
        accessorKey: "smtp",
        header: "Sending Profile",
        size: 100,
        Cell: ({ cell }) => (
          <Box>
            {cell.getValue().length > 100
              ? cell.getValue().slice(0, 100) + "..."
              : cell.getValue()}
          </Box>
        ),
      },
      {
        accessorKey: "sendbydate",
        header: "Send By Date",
        size: 100,
        Cell: ({ cell }) => (
          <Box>
            {cell.getValue().length > 100
              ? cell.getValue().slice(0, 100) + "..."
              : cell.getValue()}
          </Box>
        ),
      },
      {
        header: "Actions",
        size: 100,
        Cell: ({ cell }) => (
          <Box>
            <Button
              sx={{
                fontSize: "12px",
                bgcolor: "#299aa1", // Custom border color
                color: "white", // Make the text color match the border if needed
                "&:hover": {
                  bgcolor: "#00A3AE", // Change border color on hover
                },
              }}
              onClick={() => handleView(cell.row.original)}
            >
              View
            </Button>
          </Box>
        ),
      },
    ],
    []
  );

  const getAllCampaigns = async () => {
    try {
      const response = await axiosInstance.get("/campaigns/all");
      if (response.data.data) {
        setallCampaigns(response.data.data);
      } else {
        setallCampaigns([]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllCampaigns();
  }, []);

  return (
    <>
      <div className="w-full flex flex-col justify-start items-center">
        <ToastContainer />

        <div className="w-[90%] flex flex-row gap-5 justify-between items-center">
          <h1 className="text-[#00486F] font-bold text-[30px] mt-5 mb-5">
            All Campaigns
          </h1>
        </div>

        <div className="w-[90%] overflow-x-auto flex flex-col justify-center items-start">
          <div style={{ width: "100%", overflowX: "auto" }}>
            <MaterialReactTable
              initialState={{ density: "compact" }}
              columns={CampaignsColumns}
              data={allCampaigns}
              muiTopToolbarProps={{
                sx: {
                  backgroundColor: "#e1ecf0",
                  color: "#00486F",
                  "& .MuiSvgIcon-root": {
                    color: "#FFFFFF", // Set all icons to white in the toolbar
                  },
                  "& .MuiButtonBase-root": {
                    color: "#00486F", // Set buttons/icons (like filters, export) to white
                  },
                },
              }}
              muiTablePaperProps={{
                sx: {
                  borderRadius: "12px",
                  overflow: "hidden",
                },
              }}
              muiTablePaginationProps={{
                sx: {
                  backgroundColor: "#e1ecf0",
                  color: "#00486F",
                  "& .MuiSvgIcon-root": {
                    color: "#00486F", // Set pagination icons to white
                  },
                  "& .MuiButtonBase-root": {
                    color: "#00486F", // Set pagination buttons to white
                  },
                },
              }}
            />
          </div>
        </div>

        <Dialog open={open} onClose={handleClose}>
          <DialogTitle>Launch Campaign</DialogTitle>
          <DialogContent>
            <TextField
              disabled
              margin="dense"
              name="campaignName"
              label="Name"
              type="text"
              fullWidth
              value={selectedRow.name}
            />
            <FormControl fullWidth margin="dense">
              <InputLabel>Email Template</InputLabel>
              <Select
                disabled
                name="emailTemplate"
                value={selectedRow.template}
              >
                <MenuItem value="Test email 1">Test email 1</MenuItem>
              </Select>
            </FormControl>
            <FormControl fullWidth margin="dense">
              <InputLabel>Landing Page</InputLabel>
              <Select disabled name="landingPage" value={selectedRow.page}>
                <MenuItem value="Test Page">Test Page</MenuItem>
              </Select>
            </FormControl>
            <TextField
              disabled
              margin="dense"
              name="url"
              label="URL"
              type="url"
              fullWidth
              value={selectedRow.url}
              placeholder="http://192.168.1.1"
            />

            <TextField
              margin="dense"
              name="launchDate"
              label="Launch Date"
              type="date"
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
              value={selectedRow.launchDate}
              onChange={(e) =>
                setSelectedRow({ ...selectedRow, launchDate: e.target.value })
              }
            />
            <TextField
              disabled
              margin="dense"
              name="sendEmailsBy"
              label="Send Emails By (Optional)"
              type="date"
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
              value={selectedRow.sendbydate}
            />
            <TextField
              disabled
              margin="dense"
              name="sendingProfile"
              label="Sending Profile"
              type="text"
              fullWidth
              value={selectedRow.smtp}
              InputProps={{
                endAdornment: (
                  <InputAdornment
                    sx={{ fontSize: "12px", display: "flex", gap: "5px" }}
                    position="end"
                  >
                    <EmailIcon sx={{ fontSize: "17px" }} />
                    Send Test Email
                  </InputAdornment>
                ),
              }}
            />

            <FormControl fullWidth margin="dense">
              <InputLabel>Groups</InputLabel>
              <Select
                name="groups"
                multiple
                value={selectedRow.groups}
                onChange={handleSelectChange}
                input={<OutlinedInput label="Groups" />}
                renderValue={(selected) => (
                  <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                    {selected.map((value) => (
                      <Chip
                        key={value}
                        label={value}
                        onDelete={handleDelete(value)}
                        deleteIcon={<CloseIcon />}
                      />
                    ))}
                  </Box>
                )}
              >
                {allGroups.map((group) => (
                  <MenuItem key={group.name} value={group.name}>
                    {group.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Close</Button>
            <Button
              onClick={handleLaunchCampaign}
              sx={{
                bgcolor: "#299aa1", // Custom border color
                color: "white", // Make the text color match the border if needed
                "&:hover": {
                  bgcolor: "#00A3AE", // Change border color on hover
                },
              }}
              variant="contained"
            >
              Launch Campaign
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </>
  );
};

export default Campaigns;
