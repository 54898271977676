import React, { useEffect, useRef } from "react";
import DashboardPage from "../../contrib_dashboard/pages/DashboardPage";

const ViewDashboardModal = ({ isOpen, onClose, name, email }) => {
  const modalRef = useRef(null); // Reference to the modal content

  // Close modal when clicking outside of it
  const handleOutsideClick = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      onClose();
    }
  };

  useEffect(() => {
    if (!isOpen) return; // If modal is closed, do not add the event listener

    // Attach event listener
    document.addEventListener("mousedown", handleOutsideClick);

    // Clean up the event listener
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [isOpen]); // Run this effect when isOpen changes

  // Return null if modal is not open
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-[2000]">
      <div
        ref={modalRef}
        className="bg-white rounded-lg shadow-lg p-6 w-[90%] h-auto max-h-[90vh] relative"
      >
        <button
          className="absolute top-4 right-4 text-gray-500 hover:text-red-500 text-4xl z-10"
          onClick={onClose}
          aria-label="Close modal"
        >
          &times; {/* This is the cross (X) symbol */}
        </button>
        <h2 className="text-xl font-semibold mb-4">
          <span className=" text-[#00486F]">{email}</span> - Dashboard
        </h2>

        {/* Scrollable content area */}
        <div className="max-h-[70vh] overflow-y-auto">
          <DashboardPage viewemail={email} />
        </div>
      </div>
    </div>
  );
};

export default ViewDashboardModal;
